import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    loggedOut: Boolean,
    isLoading: Boolean,
    integrationDomain: String,
  };

  static targets = ["hostInitForm", "loader", "hostFrame"];

  connect() {
    this.isLoadingValue = false;
    this.hostFrameTarget.addEventListener("load", () => this.onLoadingEnd());

    this.onLoadingStart();
    try {
      this.hostInitFormTarget.submit();
    } catch {
      this.onLoadingEnd();
    }
  }

  // This is an example of how you'd navigate the iframe to another URL.
  // we figured out how to do it on initial load by setting the `integration_url` on the form.
  // but we will keep this implementation here for future reference and potential utility.
  deepLink() {
    const exampleDeepLink =
      "https://ustg.wisdo.com/brightline/brightline?messageDate=2024-12-10T22:30:51Z&messageId=messageId&redirectType=CHAT&roomId=roomId&roomType=GROUP&threadId=threadId";
    this.hostFrameTarget.contentWindow.postMessage(
      {
        customType: "BL_DEEP_LINK_NAVIGATION",
        deepLinkUrl: exampleDeepLink,
      },
      this.integrationDomainValue
    );
  }

  // This is also an unused implementation, but we will keep it here as a form of
  // documentation until we have a use case for it.
  logout() {
    this.hostFrameTarget.contentWindow.postMessage(
      {
        customType: "LOGOUT",
      },
      this.integrationDomainValue
    );
  }

  onLoadingStart() {
    this.isLoadingValue = true;
    this.hostFrameTarget.classList.add("hidden");
    this.hostFrameTarget.classList.add("pointer-events-none");
    this.hostFrameTarget.classList.add("opacity-0");
    this.loaderTarget.classList.remove("hidden");
  }

  onLoadingEnd() {
    this.isLoadingValue = false;
    this.hostFrameTarget.classList.remove("hidden");
    this.hostFrameTarget.classList.remove("pointer-events-none");
    this.hostFrameTarget.classList.remove("opacity-0");
    this.loaderTarget.classList.add("hidden");
  }
}
