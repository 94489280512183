import "rc-slider/assets/index.css";

import Image from "@js/components/shared/Image";
import StyleGuideClasses from "@js/helpers/StyleGuideClasses";
import DoubleBubbleIcon from "@js/images/exercises/double_bubble.png";
import Slider from "rc-slider";
import React, { HTMLAttributes, ReactElement } from "react";
import { Link, Route, Switch, useHistory, useLocation } from "react-router-dom";

import { useExerciseForm } from "./Context";
import { useThermometerImage } from "./Hooks";

const ScrollToTop = () => {
  const { pathname } = useLocation();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

const ThermometerRating: React.FC<{
  thermometerValue: number;
  setThermometerValue: any;
  elements: ThermometerElementsProps;
  id: string;
  showThermometerImage?: boolean;
  className?: string;
}> = ({
  thermometerValue,
  showThermometerImage = true,
  setThermometerValue,
  children,
  elements,
  id,
  ...rest
}) => {
  const { ThermometerImage } = useThermometerImage(
    elements.ImageMapping,
    thermometerValue
  );
  return (
    <QuestionLabel {...rest} id={id}>
      {children}
      {showThermometerImage && <ThermometerImage className="h-32" />}
      <ThermometerNumber>{thermometerValue}</ThermometerNumber>
      <div>
        <ThermometerSlider
          minValue={elements.minValue}
          maxValue={elements.maxValue}
          thermometerValue={thermometerValue}
          onChange={setThermometerValue}
          ariaLabelledByForHandle={id}
        />
        <ExerciseAnchors>
          <ExerciseAnchor className="text-left">
            {elements.minValueLabel}
          </ExerciseAnchor>
          <ExerciseAnchor className="text-right">
            {elements.maxValueLabel}
          </ExerciseAnchor>
        </ExerciseAnchors>
      </div>
    </QuestionLabel>
  );
};

const Disclaimer: React.FC = (props) => {
  return (
    <div className="max-w-2xl m-6 font-sans text-xs text-center text-gray-700 md:mt-6 md:mx-auto rounded-xl">
      Information shared in this exercise will not be actively monitored. For
      additional support, reach out to your care provider or member support. If
      needed, please see our{" "}
      <a
        href="https://www.hellobrightline.com/safety/"
        className="text-blue-800"
        target="_blank"
        rel="noopener noreferrer"
      >
        safety tips
      </a>
      .
    </div>
  );
};

const Bold: React.FC = ({ children }): JSX.Element => {
  return <span className="font-sans font-bold">{children}</span>;
};

const Paragraph: React.FC<React.HTMLAttributes<HTMLParagraphElement>> = ({
  children,
  className,
  ...rest
}): JSX.Element => {
  return (
    <p className={`font-sans text-gray-700 ${className}`} {...rest}>
      {children}
    </p>
  );
};

const Heading: React.FC = ({ children }): JSX.Element => {
  return (
    <h1 className="mt-8 font-sans text-2xl leading-9 text-gray-700 md:text-3xl md:leading-11">
      {children}
    </h1>
  );
};

const HeadingCenterBlack: React.FC = ({ children }): JSX.Element => {
  return <h1 className="mt-8 text-center">{children}</h1>;
};

const Header = (props) => {
  return (
    <h1 className="my-8 text-lg text-center text-gray-700">{props.children}</h1>
  );
};

const HaveQuestions: React.FC<{ chatLink: string | null }> = ({ chatLink }) => {
  if (!chatLink) {
    return null;
  }

  return (
    <div className="flex flex-col justify-center">
      <hr className="mt-10 mb-6" />
      <Header>Have questions?</Header>
      <SecondaryActionLink text="Message your coach" href={chatLink} />
    </div>
  );
};

const Subheading: React.FC<HTMLAttributes<HTMLHeadingElement>> = ({
  children,
  className,
  ...rest
}): JSX.Element => {
  return (
    <h2
      className={`mt-8 text-xl md:text-2xl leading-8 md:leading-9 font-sans text-gray-700 ${className}`}
      {...rest}
    >
      {children}
    </h2>
  );
};

const CenteredExerciseText: React.FC<{
  className?: string;
  htmlFor?: string;
  id?: string;
}> = ({ children, className, htmlFor, id }) => {
  return htmlFor ? (
    <label
      className={`font-sans text-gray-700 text-center ${className} block`}
      htmlFor={htmlFor}
      id={id}
    >
      {children}
    </label>
  ) : (
    <p className={`font-sans text-gray-700 text-center ${className}`} id={id}>
      {children}
    </p>
  );
};

const ThermometerNumber: React.FC<HTMLAttributes<HTMLSpanElement>> = (
  props
) => {
  const { children } = props;
  return (
    <span className="block w-full font-sans font-bold text-center text-black">
      {children}
    </span>
  );
};

const ThermometerSlider: React.FC<{
  minValue?: number;
  maxValue?: number;
  thermometerValue: number;
  onChange: any;
  ariaLabelledByForHandle: string;
}> = (props) => {
  const { readOnly } = useExerciseForm();
  return (
    <div className="py-2">
      <Slider
        min={props.minValue || 0}
        max={props.maxValue || 10}
        step={1}
        defaultValue={props.thermometerValue}
        onChange={props.onChange}
        trackStyle={{ backgroundColor: "#573977" }}
        handleStyle={{
          width: "16px",
          height: "16px",
          border: "solid 2px #573977",
        }}
        disabled={readOnly}
        ariaLabelledByForHandle={props.ariaLabelledByForHandle}
      />
    </div>
  );
};

const Wrapper: React.FC<{ className?: string }> = ({
  children,
  className = "",
}): JSX.Element => {
  return (
    <>
      <div
        className={`wrapper max-w-wrapper sm:mx-auto bg-white rounded-sm shadow-md rounded-xl ${className}`}
      >
        <div className="p-6 pt-6 sm:px-12 sm:pb-16 sm:pt-12">{children}</div>
      </div>
      <Disclaimer />
    </>
  );
};

const WrapperWithIntro: React.FC<{
  className?: string;
  intro: JSX.Element;
}> = ({ children, className = "", intro }): JSX.Element => {
  return (
    <>
      <div
        className={`wrapper max-w-wrapper sm:mx-auto bg-white rounded-sm shadow-md rounded-xl ${className}`}
      >
        <Switch>
          <Route exact path="/">
            <div className="p-6 pt-6 sm:px-24 sm:pb-16 sm:pt-12">{intro}</div>
          </Route>

          <Route>
            <div className="p-6 pt-6 sm:px-12 sm:pb-10 sm:pt-12">
              {children}
            </div>
          </Route>
        </Switch>
      </div>
      <Disclaimer />
    </>
  );
};

const GenericExerciseIntroPage = (props: {
  title: string;
  imageSrc: string;
  imageAltText: string;
  introText: string | ReactElement;
  nextPage: string;
}) => {
  const { title, imageSrc, introText, nextPage, imageAltText } = props;

  // Allow caller to override intro display if given an element
  const intro =
    typeof introText === "string" ? (
      <div className="pt-8">{introText}</div>
    ) : (
      introText
    );

  return (
    <>
      <Image
        src={imageSrc}
        alt={imageAltText}
        className={"mx-auto mt-4 w-32 h-32 sm:w-40 sm:h-40"}
      />

      <HeadingCenterBlack>{title}</HeadingCenterBlack>

      {intro}

      <ButtonGroup>
        <ExerciseCallToActionLink to={nextPage}>
          Get Started
        </ExerciseCallToActionLink>
      </ButtonGroup>
    </>
  );
};

const GenericTextAreaPromptPage = (props: {
  submitExercise: Function;
  numSteps: number;
  currentStep: number;
  textAreaValue: string;
  setTextAreaValue: Function;
  nextPage: string;
  showSaveForLater: boolean;
  setExerciseComplete: Function;
  textPrompt: ReactElement | string;
}) => {
  const {
    currentStep,
    numSteps,
    nextPage,
    textPrompt,
    textAreaValue,
    setTextAreaValue,
    submitExercise,
    setExerciseComplete,
    showSaveForLater,
  } = props;

  const getLabelId = (string: string): string => {
    return string.toLowerCase().split(" ").join("-");
  };

  // Allow caller to override prompt display if given an element
  const prompt =
    typeof textPrompt === "string" ? (
      <label className="mt-6 block" htmlFor={getLabelId(textPrompt)}>
        {textPrompt}
      </label>
    ) : (
      textPrompt
    );

  return (
    <SubmitableForm submitExercise={submitExercise}>
      <StepIndicator numSteps={numSteps} currentStep={currentStep} />

      {prompt}

      <TextArea
        className="h-40 mt-6"
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setTextAreaValue(e.target.value);
        }}
        value={textAreaValue}
        id={typeof textPrompt === "string" ? getLabelId(textPrompt) : ""}
      ></TextArea>

      <ButtonGroup>
        <ExerciseCallToActionLink to={nextPage}>Next</ExerciseCallToActionLink>

        {showSaveForLater && (
          <SaveForLaterButtonGreen
            text="Save for Later"
            setExerciseComplete={setExerciseComplete}
          />
        )}
      </ButtonGroup>
    </SubmitableForm>
  );
};

const FiveStarRating = (props: {
  value: number;
  setValue: Function;
  textValues: Array<string>;
}) => {
  const { value, setValue, textValues } = props;
  const { readOnly } = useExerciseForm();

  return (
    <>
      <div className="flex justify-between w-full mx-auto mt-12 sm:max-w-xs">
        {[1, 2, 3, 4, 5].map((val) => {
          const [icon, color] =
            val > value
              ? ["star_border", "text-gray-600"]
              : ["star", "text-orange-700"];

          return (
            <button
              value={val}
              key={val}
              className={`text-4xl material-icons focus:outline-none ${color}`}
              onClick={(e) => {
                e.preventDefault();
                setValue(e.currentTarget.value);
              }}
              disabled={readOnly}
            >
              {icon}
            </button>
          );
        })}
      </div>

      <p className="mt-5 text-center">{textValues[value] || textValues[0]}</p>
    </>
  );
};

const FiveStarRatingResults = (props: {
  value: number;
  textValue: string;
  title: string;
}) => {
  const { value, textValue, title } = props;

  return (
    <div className="mt-10 ">
      <p className="font-bold text-center uppercase">{title}</p>
      <p className="my-2 text-center">{textValue}</p>
      <div className="flex justify-between w-40 mx-auto">
        {[1, 2, 3, 4, 5].map((val) => {
          const [icon, color] =
            val > value
              ? ["star_border", "text-gray-600"]
              : ["star", "text-orange-700"];

          return (
            <span
              key={val}
              className={`text-xl material-icons focus:outline-none ${color}`}
            >
              {icon}
            </span>
          );
        })}
      </div>
    </div>
  );
};

const Form = (props): JSX.Element => {
  return (
    <form onSubmit={props.onSubmit} data-testid="form">
      {props.children}
    </form>
  );
};

const SubmitableForm = (props): JSX.Element => {
  return (
    <Form
      onSubmit={(e: React.ChangeEvent<HTMLFormElement>) => {
        props.submitExercise(e);
      }}
    >
      {props.children}
    </Form>
  );
};

const PineLetter: React.FC<React.HTMLAttributes<HTMLSpanElement>> = (props) => {
  const { children, className } = props;
  return (
    <span className={`text-green-700 font-sans font-bold ${className}`}>
      {children}
    </span>
  );
};

const YesNoRadio: React.FC<{
  radioName: string;
  checked: boolean;
  setChecked: any;
}> = (props) => {
  const { readOnly } = useExerciseForm();
  return (
    <div className="inline-block ml-4">
      <label
        className="inline-flex items-center"
        htmlFor={`${props.radioName}-yes`}
      >
        <input
          type="radio"
          id={`${props.radioName}-yes`}
          className="form-radio text-marigold"
          name={props.radioName}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            props.setChecked(true);
          }}
          checked={props.checked === true}
          disabled={readOnly}
        />
        <span className="ml-2">Yes</span>
      </label>
      <label
        className="inline-flex items-center ml-2"
        htmlFor={`${props.radioName}-no`}
      >
        <input
          id={`${props.radioName}-no`}
          type="radio"
          className="text-gray-700 form-radio"
          name={props.radioName}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            props.setChecked(false);
          }}
          checked={props.checked === false}
          disabled={readOnly}
        />
        <span className="ml-2">No</span>
      </label>
    </div>
  );
};

const BasicThermometer = (props: {
  value: number;
  setValue: Function;
  ariaLabelledByForHandle: string;
}) => {
  const { value, setValue, ariaLabelledByForHandle } = props;
  return (
    <>
      <ThermometerNumber>{value}</ThermometerNumber>
      <div className="py-4">
        <ThermometerSlider
          thermometerValue={value}
          onChange={setValue}
          ariaLabelledByForHandle={ariaLabelledByForHandle}
        />
        <ExerciseAnchors>
          <ExerciseAnchor className="text-left">Very Bad</ExerciseAnchor>
          <ExerciseAnchor className="text-right">Very Good</ExerciseAnchor>
        </ExerciseAnchors>
      </div>
    </>
  );
};

const QuestionLabel: React.FC<React.AllHTMLAttributes<HTMLLabelElement>> = ({
  htmlFor,
  ...rest
}): JSX.Element => {
  return (
    <label
      htmlFor={htmlFor}
      {...rest}
      className={`${rest.className} my-2 block`}
    />
  );
};

const TextArea = (props): JSX.Element => {
  const { className, ...rest } = props;
  const { readOnly } = useExerciseForm();
  return (
    <textarea
      className={`w-full p-2 rounded-md form-textarea ${className} disabled:opacity-50`}
      rows={3}
      disabled={readOnly}
      {...rest}
    />
  );
};

const LabeledInput = ({
  label,
  onChange,
  "data-testid": dataTestId,
  value,
  ...rest
}) => {
  const { readOnly } = useExerciseForm();
  const labelId = `${dataTestId}-label`;
  return (
    <div
      className="relative flex flex-wrap items-stretch w-full mb-4"
      {...rest}
    >
      <div className="flex -mr-px">
        <span
          id={labelId}
          className="flex items-center justify-center w-10 text-sm leading-normal border border-r-0 rounded rounded-r-none whitespace-nowrap bg-cream border-grey-light text-grey-dark"
        >
          {label}
        </span>
      </div>
      <input
        type="text"
        className="relative flex-auto flex-grow flex-shrink w-px h-10 px-3 leading-normal border rounded rounded-l-none border-grey-light focus:border-blue focus:shadow disabled:opacity-50"
        data-testid={dataTestId}
        onChange={onChange}
        value={value}
        disabled={readOnly}
        aria-labelledby={labelId}
      />
    </div>
  );
};

const TextInput: React.FC<React.HTMLProps<HTMLInputElement>> = (
  props
): JSX.Element => {
  const { className = "", children, ...rest } = props;
  const { readOnly } = useExerciseForm();
  return (
    <input
      type="text"
      className={`input mt-1 block w-full disabled:opacity-50 ${className}`}
      disabled={readOnly}
      {...rest}
    />
  );
};

const BigOldLetter = (props): JSX.Element => {
  return (
    <div className="flex items-center justify-center w-1/12 text-center md:w-1/5">
      <span
        className="font-sans text-5xl font-bold text-orange-700"
        {...props}
      />
    </div>
  );
};

const InputGroup = (props): JSX.Element => {
  const { children, className = "", ...rest } = props;
  return (
    <div className={`w-11/12 pl-4 md:w-4/5 ${className}`} {...rest}>
      {children}
    </div>
  );
};

const ExerciseErrorMessage = (props): JSX.Element => {
  return <span className="text-sm text-marigold" {...props} />;
};

const Title: React.FC<{ className?: string }> = ({
  children,
  className = "",
}) => {
  return (
    <span
      className={`block pt-6 pb-8 text-gray-700 font-sans font-bold ${className}`}
    >
      {children}
    </span>
  );
};

const RoundedExerciseCard: React.FC<{ color?: string; className?: string }> = ({
  children,
  color = "almond",
  className,
}) => {
  return (
    <div
      className={`text-center shadow bg-${color} align-center rounded-2xl text-lightBrown ${className}`}
    >
      {children}
    </div>
  );
};

const ExerciseChildIcon: React.FC = () => {
  return (
    <span className="w-full h-full pt-8 text-gray-700 material-icons text-7xl text-lightBrown">
      escalator_warning
    </span>
  );
};

const ExerciseFearLadderIcon = (props) => {
  return (
    <svg
      width={68}
      height={39}
      viewBox="0 0 68 39"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <title>{"Fear-ladder-icon"}</title>
      <g fill="#9EDCD0" fillRule="nonzero">
        <rect x={0.487} y={14.604} width={9.736} height={24.341} rx={4.868} />
        <rect x={15.091} width={9.736} height={38.945} rx={4.868} />
        <rect x={29.696} y={9.736} width={9.736} height={29.209} rx={4.868} />
        <rect x={43.083} width={9.736} height={38.945} rx={4.868} />
        <rect x={57.688} y={19.473} width={9.736} height={19.473} rx={4.868} />
      </g>
    </svg>
  );
};

const ExerciseDoubleBubbleIcon: React.FC<{ className?: string }> = ({
  className,
}) => {
  return (
    <Image
      src={DoubleBubbleIcon}
      className={`mx-auto text-lightBrown pt-4 ${className}`}
    />
  );
};

// TODO: Make this part of Wrapper?
const ExerciseNavigation = (props: { title: string }) => {
  const history = useHistory();
  const { title } = props;

  return (
    <>
      <div className="absolute top-0 left-0 flex items-center w-full h-16 text-center bg-white border-b-2 border-gray-300 md:hidden justify-evenly">
        <button
          className="w-8 cursor-pointer material-icons focus:outline-none"
          onClick={(event) => {
            event.preventDefault();
            history.goBack();
          }}
        >
          arrow_back
        </button>
        <span>{title}</span>
        <span className="w-8"></span>
      </div>

      {/* Don't display desktop back button on the first page of the exercise */}
      <Switch>
        <Route exact path="/"></Route>

        <Route>
          <button
            className="absolute hidden text-center material-icons md:block"
            onClick={(event) => {
              event.preventDefault();
              history.goBack();
            }}
          >
            arrow_back
          </button>
        </Route>
      </Switch>
    </>
  );
};

const ButtonGroup = (props) => {
  return (
    <div className="flex flex-col justify-center w-full mx-auto sm:max-w-min">
      {props.children}
    </div>
  );
};

const StepIndicator = (props) => {
  const { numSteps, currentStep } = props;
  const history = useHistory();
  let circles: Array<JSX.Element> = [];

  for (let x = 1; x <= numSteps; x++) {
    const color = currentStep === x ? "text-orange-700" : "text-gray-300";
    const classes = `material-icons px-0.5 text-xs ${color}`;
    circles.push(
      <span key={x} className={classes}>
        circle
      </span>
    );
  }

  return (
    <div className="w-full mb-4 text-center">
      <div>{circles}</div>
    </div>
  );
};

const PaddedListItem = (props) => {
  return <li className="pt-4 text-mediumBrown">{props.children}</li>;
};

const DiscList = (props) => {
  return <ul className="pl-4 list-disc list-inside">{props.children}</ul>;
};

// TODO: Change "Cancel" link to "Back" and navigate back in history
// instead of hard-coding the path in the caller?
const ResumableExerciseActions = (props: ResumableExerciseActionProps) => {
  const {
    setExerciseComplete,
    cancelPath,
    submitButtonText = "Log",
    saveForLaterButtonText = "Save for Later",
    showSaveForLaterButton = false,
  } = props;

  const saveForLaterButton = !showSaveForLaterButton ? null : (
    <SaveForLaterButton
      text={saveForLaterButtonText}
      setExerciseComplete={setExerciseComplete}
    />
  );

  return (
    <ActionButtons>
      <FormSubmitButton
        text={submitButtonText}
        setExerciseComplete={setExerciseComplete}
      />
      {saveForLaterButton}
      {cancelPath && <SecondaryActionLink text="Cancel" to={cancelPath} />}
    </ActionButtons>
  );
};

const ActionButtons = ({ children }) => {
  const { careLink, preview, readOnly } = useExerciseForm();

  const previewPaddingClass = preview ? "mb-12" : "";

  return preview || readOnly ? (
    <div className={`text-center ${previewPaddingClass}`.trim()}>
      <a
        href={careLink}
        className={StyleGuideClasses.submit("w-full mx-auto mt-12 mb-2")}
      >
        Back to Care
      </a>
    </div>
  ) : (
    children
  );
};

const FormSubmitButton = (props: ButtonProps) => {
  const { setExerciseComplete, text, disabled } = props;
  const { readOnly } = useExerciseForm();
  return (
    <button
      className={StyleGuideClasses.submit("w-full mt-10 mb-2")}
      onClick={() => setExerciseComplete?.(true)}
      disabled={readOnly || disabled}
    >
      {text}
    </button>
  );
};

const SaveForLaterButton = (props: ButtonProps) => {
  const { setExerciseComplete, text, disabled } = props;
  const { readOnly } = useExerciseForm();

  return (
    <button
      className="w-full mt-2 mb-2 btn btn-secondary disabled:opacity-50 disabled:cursor-default whitespace-nowrap"
      onClick={() => setExerciseComplete?.(false)}
      disabled={readOnly || disabled}
    >
      {text}
    </button>
  );
};

// Newer design of the Save For Later button with green text and border but white background
const SaveForLaterButtonGreen = (props: ButtonProps) => {
  const { setExerciseComplete, text, disabled } = props;
  const { readOnly } = useExerciseForm();

  return (
    <button
      className={StyleGuideClasses.save("w-full")}
      onClick={() => setExerciseComplete?.(false)}
      disabled={readOnly || disabled}
    >
      {text}
    </button>
  );
};

const ExerciseCallToActionLink: React.FC<{
  className?: string;
  disabled?: boolean;
  to: string;
}> = ({ className = "", children, disabled = false, to }) => {
  const sharedClassNames = StyleGuideClasses.submit(`mt-12 mb-2 ${className}`);

  if (disabled) {
    return (
      <Link
        to={null}
        className={`${sharedClassNames} cursor-default opacity-50`}
        {...{ "data-disabled": true }}
      >
        {children}
      </Link>
    );
  } else {
    return (
      <Link to={to} className={sharedClassNames}>
        {children}
      </Link>
    );
  }
};

const Caption: React.FC<React.HTMLAttributes<HTMLSpanElement>> = (props) => {
  const { children, className } = props;
  return <span className={`text-sm ${className}`}>{children}</span>;
};

const ExerciseAnchors: React.FC<HTMLAttributes<HTMLDivElement>> = (props) => {
  const { className, children, ...rest } = props;
  return (
    <div
      className={`flex justify-between text-sm text-mediumBrown ${className}`}
      {...rest}
    >
      {children}
    </div>
  );
};

const ExerciseAnchor: React.FC<HTMLAttributes<HTMLSpanElement>> = (props) => {
  const { className, children, ...rest } = props;

  return (
    <span className={`w-full ${className}`} {...rest}>
      {children}
    </span>
  );
};

const SecondaryActionLink = (props: LinkProps) => {
  if (props.to) {
    return (
      <Link
        className="w-full mt-1 text-center text-green-700 btn btn-link"
        to={props.to}
      >
        {props.text}
      </Link>
    );
  } else if (props.href) {
    return (
      <a
        className="w-full mt-1 text-center text-green-700 btn btn-link"
        href={props.href}
      >
        {props.text}
      </a>
    );
  } else {
    throw new Error("Please specify either an href or a to property");
  }
};

const TextInputPromptSingle = (
  label: string,
  val: string,
  callback: Function,
  placeholder?: string
) => {
  const labelElement = <Bold>{label}</Bold>;
  const id = `${label.toLowerCase().replace(/\s/g, "-")}-input`;

  return (
    <QuestionLabel htmlFor={id}>
      <Paragraph>
        {labelElement}
        <TextInput
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            callback(e.target.value)
          }
          autoComplete="off"
          value={val}
          placeholder={placeholder}
          id={id}
        ></TextInput>
      </Paragraph>
    </QuestionLabel>
  );
};

const TextAreaPromptSingle = (
  label: string,
  val: string,
  callback: Function,
  placeholder?: string
) => {
  const id = `${label.toLowerCase().replace(/\s/g, "-")}-input`;

  return (
    <QuestionLabel htmlFor={id}>
      <Paragraph>
        <Bold>{label}</Bold>
        <TextArea
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            callback(e.target.value)
          }
          value={val}
          id={id}
        ></TextArea>
      </Paragraph>
    </QuestionLabel>
  );
};

const Blockquote: React.FC<{ quotes?: boolean; className?: string }> = (
  props
) => {
  const { children, className = "", quotes = true } = props;
  const quoteString = quotes ? '"' : "";
  return (
    <p
      className={`border-green-700 border-l-2 bg-cream my-4 p-4 text-lg text-darkBrown ${className}`}
    >
      {quoteString}
      {children}
      {quoteString}
    </p>
  );
};

const LinkGroup: React.FC = (props) => {
  return <div className="flex flex-col justify-center">{props.children}</div>;
};

const HR: React.FC = () => {
  return <hr className="h-px mb-4 bg-marigold" />;
};

const TableCell = (props) => {
  const className = props.className || "";
  return (
    <div
      className={`flex-grow w-full md:w-1/3 box-border overflow-hidden list-none border-2 border-white ${className}`}
    >
      {props.children}
    </div>
  );
};

const MobileOnlyText = (props) => {
  return (
    <span className="inline font-sans font-bold md:hidden">
      {props.children}
    </span>
  );
};

const Table = (props) => {
  return <div className="flex flex-wrap p-0 m-0">{props.children}</div>;
};

interface LinkProps {
  text: string;
  href?: string;
  to?: string;
  setExerciseComplete?: any;
}

interface ResumableExerciseActionProps {
  submitButtonText?: string;
  saveForLaterButtonText?: string;
  cancelPath?: string;
  setExerciseComplete: Function;
  showSaveForLaterButton?: boolean;
}

interface ButtonProps {
  text: string;
  setExerciseComplete?: Function;
  disabled?: boolean;
}

interface ExerciseScreenProps {
  assignedExerciseId: string;
  patientFirstName: string;
  patientLastName: string;
  chatLink: string;
  careLink: string;
  libraryLink: string;
  componentName: string;
  csrfToken: string;
  additionalData?: any;
  completedExercise?: {
    id: number;
    data: any;
  };
  readOnly?: boolean;
  preview?: boolean;
}

interface GenericMultiPageExerciseScreenProps {
  currentStep: number;
  numSteps: number;
  nextPage: string;
}

interface BodyMapMultiPageScreenProps {
  propsReadOnly: boolean;
}

interface ThermometerElementsProps {
  Title: React.FC;
  IntroductionText: React.FC;
  InstructionsText: React.FC;
  ImageMapping: any[];
  imageIsFace?: boolean;
  initialThermometerValue: number;
  introductionImageValue?: number;
  minValue?: number;
  minValueLabel: string;
  maxValue?: number;
  maxValueLabel: string;
  exerciseQuestion: string;
}

const ExerciseElements = {
  ActionButtons,
  BasicThermometer,
  BigOldLetter,
  Blockquote,
  ButtonGroup,
  Bold,
  Caption,
  CenteredExerciseText,
  Disclaimer,
  DiscList,
  ExerciseAnchor,
  ExerciseAnchors,
  ExerciseCallToActionLink,
  FiveStarRating,
  FiveStarRatingResults,
  ExerciseNavigation,
  Form,
  FormSubmitButton,
  GenericExerciseIntroPage,
  GenericTextAreaPromptPage,
  HaveQuestions,
  Header,
  Heading,
  HeadingCenterBlack,
  HR,
  InputGroup,
  LabeledInput,
  LinkGroup,
  MobileOnlyText,
  PaddedListItem,
  Paragraph,
  PineLetter,
  QuestionLabel,
  ResumableExerciseActions,
  RoundedExerciseCard,
  SaveForLaterButton,
  SaveForLaterButtonGreen,
  ScrollToTop,
  SecondaryActionLink,
  StepIndicator,
  Subheading,
  SubmitableForm,
  Table,
  TableCell,
  TextArea,
  TextAreaPromptSingle,
  TextInput,
  TextInputPromptSingle,
  ThermometerNumber,
  ThermometerSlider,
  ThermometerRating,
  Title,
  Wrapper,
  WrapperWithIntro,
  YesNoRadio,
};

const Icons = {
  ExerciseChildIcon,
  ExerciseDoubleBubbleIcon,
  ExerciseFearLadderIcon,
};

// TODO: Remove most elements in favor of ExerciseElements object
export {
  ThermometerElementsProps,
  ExerciseScreenProps,
  GenericMultiPageExerciseScreenProps,
  BodyMapMultiPageScreenProps,
  ExerciseElements,
  ExerciseErrorMessage,
  Icons,
  ExerciseChildIcon,
  ExerciseDoubleBubbleIcon,
};
